"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSetting = exports.getSettingInfo = exports.getSettingCount = exports.getSettingById = exports.getSettingByEmail = exports.getSetting = exports.deleteSetting = exports.defaultSettingData = exports.addSetting = exports.StoreStoreSetting = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSettingData = exports.defaultSettingData = {
  id: 0,
  enabled: true,
  maxAllowedVideos: 0,
  numberOfMembersAllowedInTeam: 0,
  userFollowThreshold: 0,
  peerRating: 0,
  leaderBoardTopX: 0,
  defaultCoachRating: 0,
  defaultPlayerRating: 0,
  minimumCoachRateCount: 0,
  ratingCalculationTimeInMonths: 0,
  maxVideoLenght: null,
  minimumNumberOfUsersReported: 10,
  coachRating: 0
};
const getSetting = params => (0, _request.default)({
  url: '/settings',
  method: 'get',
  params
});
exports.getSetting = getSetting;
const getSettingById = id => (0, _request.default)({
  url: `/settings/${id}`,
  method: 'get'
});
exports.getSettingById = getSettingById;
const getSettingInfo = data => (0, _request.default)({
  url: '/settings/me',
  method: 'post',
  data
});
exports.getSettingInfo = getSettingInfo;
const getSettingByEmail = StoreSettingemail => (0, _request.default)({
  url: `/settings/${StoreSettingemail}`,
  method: 'get'
});
exports.getSettingByEmail = getSettingByEmail;
const updateSetting = (id, data) => (0, _request.default)({
  url: `/settings/${id}`,
  method: 'patch',
  data
});
exports.updateSetting = updateSetting;
const StoreStoreSetting = (id, data) => (0, _request.default)({
  url: `/settings/${id}`,
  method: 'patch',
  data
});
exports.StoreStoreSetting = StoreStoreSetting;
const deleteSetting = StoreSettingname => (0, _request.default)({
  url: `/settings/${StoreSettingname}`,
  method: 'delete'
});
exports.deleteSetting = deleteSetting;
const addSetting = data => (0, _request.default)({
  url: '/settings/',
  method: 'post',
  data
});
exports.addSetting = addSetting;
const getSettingCount = params => (0, _request.default)({
  url: '/settings/count',
  method: 'get',
  params
});
exports.getSettingCount = getSettingCount;