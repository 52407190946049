"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _bokingenquiry = require("@/api/bokingenquiry");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admins = require("@/api/admins");
var _timemaster = require("@/api/timemaster");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.newList = [];
    this.oldList = [];
    this.showSortingOption = false;
    this.renderComponent = true;
    this.downloadLoading = false;
    this.updateDialogVisible = false;
    this.assignDialogVisible = false;
    this.assignGooleMeet = false;
    this.rescheduleDialogVisible = false;
    this.rescheduleMeet = false;
    this.slotsList = [];
    this.activeTab = "first";
    // private googleMeetForm = Object.assign({}, defaultBookingData.link);
    this.renderKey = 0;
    // private rescheduledForm= Object.assign({
    //   date:"",
    //   timeId:null,
    //   type:"",
    //   assignedTo:{},
    //    user:{},
    //    time:{},
    //   assignedToId:null,
    //   userId:null,
    // },defaultBookingData)
    this.postForm = Object.assign({}, _bokingenquiry.defaultBookingData);
    this.googleMeetForm = {
      link: ''
    };
    this.nutrationistList = [];
    this.adminList = [];
    this.assignData1 = {
      id: 0,
      assignedToId: null,
      assignedTo: {
        id: null
      },
      status: null
    };
    this.listQuery = {
      page: 1,
      limit: 20,
      sort: "id,DESC",
      fullName: "",
      title: "",
      date: "",
      status: 0,
      filter: {
        "user.fullName": "$contL",
        "time.title": "$contL",
        date: "$contL",
        status: "eq",
        enabled: "eq"
      }
    };
    this.sloatQuery = {
      sort: "id,DESC",
      enabled: true,
      filter: {
        enabled: "eq"
      }
    };
    this.nutrationistQuery = {
      sort: "id,DESC",
      enabled: true,
      // adminsRoleId:1,
      filter: {
        enabled: "eq"
      }
    };
    this.adminQuery = {
      sort: "id,DESC",
      enabled: true,
      adminsRoleId: 2,
      filter: {
        enabled: "eq",
        adminsRoleId: "eq"
      }
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.bookingData = {
      status: null
    };
    // =
    //  {
    //   status: null,
    // };
    this.filters = [];
    this.assignTolist = [];
    this.statusList = [{
      label: "New Enquiry",
      value: 0
    }, {
      label: "Assign",
      value: 1
    }, {
      label: "Pipeline",
      value: 2
    }, {
      label: "Not Interested",
      value: 3
    }, {
      label: "Enrolled",
      value: 4
    }];
    this.PrefranceList = [{
      label: "video call",
      value: 0
    }, {
      label: "voice call",
      value: 1
    }];
    this.rules = {
      name: [{
        required: true,
        message: "Please Enter Full Name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      lastName: [{
        required: true,
        message: "Please Enter Last Name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      email: [{
        required: true,
        message: "Please enter email",
        trigger: "blur"
      }, {
        type: "email",
        message: "Please enter correct email",
        trigger: ["blur", "change"]
      }],
      contactNumber: [{
        required: true,
        message: "Please enter mobile number",
        trigger: "blur"
      }],
      // profilePicture: [
      //   {
      //     required: true,
      //     message: 'Profile picture is required'
      //   }
      // ],
      gender: [{
        required: true,
        message: "Please Select Gender",
        trigger: ["blur", "change"]
      }],
      age: [{
        required: true,
        message: "Please Enter Age",
        trigger: ["blur", "change"]
      }],
      height: [{
        required: true,
        message: "Please Enter Height",
        trigger: ["blur", "change"]
      }],
      heightType: [{
        required: true,
        message: "Please Select HeightType",
        trigger: ["blur", "change"]
      }],
      weight: [{
        required: true,
        message: "Please Enter Weight",
        trigger: ["blur", "change"]
      }],
      weightType: [{
        required: true,
        message: "Please Select WeightType",
        trigger: ["blur", "change"]
      }],
      countryId: [{
        required: true,
        message: "Please Select Country",
        trigger: ["blur", "change"]
      }],
      assignedToId: [{
        required: true,
        message: "Please assign ",
        trigger: ["blur", "change"]
      }],
      cycleFrom: [{
        required: true,
        message: "Please Select Date of Cycle From",
        trigger: ["blur", "change"]
      }],
      cycleTo: [{
        required: true,
        message: "Please Select Date of Cycle To",
        trigger: ["blur", "change"]
      }],
      languageIds: [{
        required: true,
        message: "Please Select Language",
        trigger: ["blur", "change"]
      }],
      workoutIds: [{
        required: true,
        message: "Please Select Workout",
        trigger: ["blur", "change"]
      }],
      diseaseIds: [{
        required: true,
        message: "Please Select Disease",
        trigger: ["blur", "change"]
      }],
      date: [{
        required: true,
        message: "Please Select Date",
        trigger: ["blur", "change"]
      }],
      timeId: [{
        required: true,
        message: "Please Select Time",
        trigger: ["blur", "change"]
      }],
      type: [{
        required: true,
        message: "Please Select Prefrance",
        trigger: ["blur", "change"]
      }],
      collegeId: [{
        required: true,
        message: "Please Select College Name",
        trigger: ["blur", "change"]
      }],
      pincode: [{
        required: true,
        message: "Pincode is required"
      }],
      companyName: [{
        required: true,
        message: "Company Name is required"
      }],
      companyBio: [{
        required: true,
        message: "Company Bio is required"
      }],
      companyContactNumber: [{
        required: true,
        message: "Company Contact Number is required"
      }],
      companyAddress: [{
        required: true,
        message: "Company Address is required"
      }]
    };
  }
  handleClick(tab, event) {
    switch (tab.index) {
      case tab.index = "0":
        {
          this.listQuery.status = 0;
          this.getList();
          break;
        }
      case tab.index = "1":
        {
          this.listQuery.status = 1;
          this.getList();
          break;
        }
      case tab.index = "2":
        {
          this.listQuery.status = 2;
          this.getList();
          break;
        }
      case tab.index = "3":
        {
          this.listQuery.status = 3;
          this.getList();
          break;
        }
      case tab.index = "4":
        {
          this.listQuery.status = 4;
          this.getList();
          break;
        }
    }
  }
  created() {
    this.getList();
    this.adminListData();
    this.getSloatsData();
    this.nutraionList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _bokingenquiry.getBooking)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  selectStatus(selectedId) {
    console.log("selected Id", selectedId);
  }
  openWhatsAppChat(phoneNumber) {
    const whatsappURL = `https://wa.me/${phoneNumber}`;
    window.open(whatsappURL, '_blank');
  }
  addGoogleMeet(link) {
    // const whatsappURL = `https://wa.me/${phoneNumber}`;
    window.open(link, '_blank');
  }
  async adminListData() {
    try {
      this.listLoading = true;
      const data = await (0, _admins.getAdmins)((0, _index2.getQuery)(this.listQuery));
      this.assignTolist = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async nutraionList() {
    try {
      this.listLoading = true;
      const data = await (0, _admins.getAdmins)((0, _index2.getQuery)(this.nutrationistQuery));
      this.nutrationistList = data;
      const data1 = await (0, _admins.getAdmins)((0, _index2.getQuery)(this.adminQuery));
      this.adminList = data1;
      console.log("eeeee", this.nutrationistList);
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  // private async getFilters() {
  //   this.filters = await getFilters(
  //     getQuery({
  //       sort: 'name,ASC'
  //     })
  //   )
  // }
  handleFilter() {
    // this.sloatQuery.page = 1;
    this.getList();
  }
  async onPriortyChange() {
    if (this.showSortingOption) {
      this.fetchAllRecored(true);
    } else {
      this.renderComponent = false;
      this.sortable.options.disabled = true;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
  changeStatus(row) {
    this.bookingData = row;
    this.updateDialogVisible = true;
  }
  DirectAssignTo(row) {
    this.assignData1 = row;
    this.updateDialogVisible = false;
    this.assignDialogVisible = true;
  }
  googlemeet(row) {
    this.assignData1 = row;
    this.googleMeetForm.link = this.assignData1.link;
    this.assignGooleMeet = true;
    this.updateDialogVisible = false;
    this.assignDialogVisible = false;
  }
  reschedule(row) {
    this.fetchData(row);
    this.assignData1 = row;
    this.updateDialogVisible = false;
    this.assignDialogVisible = false;
    this.rescheduleMeet = true;
  }
  async getSloatsData() {
    const data = await (0, _timemaster.getTimeMaster)((0, _index2.getQuery)(this.sloatQuery));
    this.slotsList = data;
  }
  async updateStatus() {
    if (this.bookingData.assignedToId) {
      let data = {
        status: this.bookingData.status,
        assignedToId: this.bookingData.assignedToId,
        assignedTo: {
          id: this.bookingData.assignedToId
        }
      };
      this.listLoading = true;
      await (0, _bokingenquiry.updateBooking)(this.bookingData.id, data).then(res => {
        this.$message({
          message: "Status Updated.",
          type: "success"
        });
        this.getList();
        this.listLoading = false;
        this.updateDialogVisible = false;
      });
    } else if (this.bookingData.assignedToId === null) {
      let data = {
        status: this.bookingData.status
      };
      this.listLoading = true;
      await (0, _bokingenquiry.updateBooking)(this.bookingData.id, data).then(res => {
        this.$message({
          message: "Status Updated.",
          type: "success"
        });
        this.getList();
        this.listLoading = false;
        this.updateDialogVisible = false;
      });
    }
  }
  // else {
  //   this.AssignTo(this.assignData1);
  // }
  async assignStatus() {
    let data = {
      assignedToId: this.assignData1.assignedToId,
      assignedTo: {
        id: this.assignData1.assignedToId
      },
      status: 1
    };
    if (data.assignedToId) {
      this.listLoading = true;
      await (0, _bokingenquiry.updateBooking)(this.assignData1.id, data).then(res => {
        this.$message({
          message: "Assigned Updated.",
          type: "success"
        });
        this.getList();
        this.listLoading = false;
        this.assignDialogVisible = false;
        this.updateDialogVisible = false;
      });
    } else {
      this.$message({
        message: "Error Occured.",
        type: "warning",
        duration: 3000
      });
    }
  }
  // private async addGoogleMeet() {
  //   try {
  //     this.listLoading = true;
  //     await updateBooking(this.assignData1.id, this.googleMeetForm).then(
  //       (res: any) => {
  //         this.$message({
  //           message: "Link Added Successfully.",
  //           type: "success",
  //         });
  //         this.getList();
  //         this.listLoading = false;
  //         this.assignDialogVisible = false;
  //         this.updateDialogVisible = false;
  //         this.assignGooleMeet = false;
  //       }
  //     );
  //   } catch (err) {
  //     this.listLoading = false;
  //   }
  // }
  async addReschedule(data) {
    try {
      this.$refs.postForm.validate(async valid => {
        if (valid) {
          this.listLoading = true;
          //  this.postForm.assignedToId=this.assignData1.userId;
          // const date1 = new Date(this.postForm.date);
          // let day = String(date1.getDate()).padStart(2, "0");
          // let month = String(date1.getMonth() + 1).padStart(2, "0"); // January is 0!
          // let year = date1.getFullYear();
          // let formattedDate = `${day}-${month}-${year}`;
          // this.postForm.date = formattedDate;
          this.postForm.status = 1;
          this.postForm.assignedTo = {
            id: this.postForm.assignedToId
          };
          this.postForm.userId = this.assignData1.userId;
          this.postForm.user = {
            id: this.assignData1.userId
          };
          this.postForm.time = {
            id: this.postForm.timeId
          };
          // this.postForm.user= {
          //    this.postForm.userId=this.assignData1.id;
          // }
          await (0, _bokingenquiry.updateBooking)(this.assignData1.id, this.postForm).then(res => {
            this.$message({
              message: "Booking Reschedule Successfully.",
              type: "success"
            });
            this.getList();
            this.listLoading = false;
            this.assignDialogVisible = false;
            this.updateDialogVisible = false;
            this.assignGooleMeet = false;
            this.rescheduleDialogVisible = false;
            this.rescheduleMeet = false;
          });
        } else {
          return false;
        }
      });
    } catch (err) {
      this.listLoading = false;
    }
  }
  async fetchData(result) {
    try {
      const data = await (0, _bokingenquiry.getBookingById)(result.id);
      this.renderKey++;
      // this.postForm.profilePicture=data.profilePicture;
      //  let formattedDate= moment(data.date).format('DD-MM-YYYY');
      // this.postForm.date = formattedDate; 
      this.postForm = data;
    } catch (err) {
      Promise.reject(err);
    }
  }
  async fetchAllRecored(checkForFirstTime) {
    try {
      this.listLoading = true;
      const data = await (0, _bokingenquiry.getBooking)((0, _index2.getQuery)({
        page: 1,
        limit: 100000,
        sort: "priority,ASC",
        filter: {
          enabled: "eq",
          isDeleted: "eq"
        }
      }));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
      if (checkForFirstTime) {
        this.oldList = this.list.map(v => v.id);
        this.newList = this.oldList.slice();
        this.$nextTick(this.setSort);
      }
    } catch (error) {
      this.listLoading = false;
    }
  }
  async handleCloseDialog() {
    this.updateDialogVisible = false;
    this.assignDialogVisible = false;
    this.assignGooleMeet = false;
  }
  setSort() {
    const temp = this.$refs.dragTable;
    const el = temp.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: "sortable-ghost",
      setData: function (dataTransfer) {
        dataTransfer.setData("Text", "");
      },
      onEnd: evt => {
        const targetRow = this.list.splice(Number(evt.oldIndex), 1)[0];
        this.list.splice(Number(evt.newIndex), 0, targetRow);
        const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
        this.newList.splice(evt.newIndex, 0, tempIndex);
        console.log(this.newList);
      }
    });
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _bokingenquiry.deleteBooking)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Full Name", "Email", "Contact Number", "Status", "timestamp"];
    const filterVal = ["id", "fullName", "email", "contactNumber", "enabled", "createdTimestamp"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.enquiry.description"));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "EnquiryList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;