"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "180px"
    },
    attrs: {
      placeholder: "Plan",
      clearable: ""
    },
    on: {
      input: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery["plan.title"],
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "plan.title", $$v);
      },
      expression: "listQuery['plan.title']"
    }
  }), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "140px"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.sort,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "sort", $$v);
      },
      expression: "listQuery.sort"
    }
  }, _vm._l(_vm.sortOptions, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), this.$route.params.id ? _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/usersCase/add/" + _vm.listQuery.userId
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" Add Subscription ")])], 1) : _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/usersCase/add/"
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" Add Subscription ")])], 1), _c("el-checkbox", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-left": "15px"
    },
    on: {
      change: function ($event) {
        _vm.tableKey = _vm.tableKey + 1;
      }
    },
    model: {
      value: _vm.showCreatedDate,
      callback: function ($$v) {
        _vm.showCreatedDate = $$v;
      },
      expression: "showCreatedDate"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.createdDate")) + " ")])], 1), _vm.listQuery.userId ? _c("div", [_vm.listQuery.userId ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "dragTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "row-key": "id",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "100",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      width: "130",
      align: "center",
      label: "Enable/Disable",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          key: scope.row.id,
          attrs: {
            url: "/user-transactions/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        })];
      }
    }], null, false, 3728663803)
  }), _c("el-table-column", {
    attrs: {
      width: "400px",
      align: "center",
      label: "User Details"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$user, _scope$row$user2, _scope$row$user3, _scope$row$user4;
        return [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", [_c("b", [_vm._v("Name: ")])]), _c("el-col", [_vm._v(_vm._s((_scope$row$user = scope.row.user) === null || _scope$row$user === void 0 ? void 0 : _scope$row$user.fullName))])], 1)], 1), _c("br"), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("Phone Number: ")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("span", [_vm._v(_vm._s((_scope$row$user2 = scope.row.user) === null || _scope$row$user2 === void 0 ? void 0 : _scope$row$user2.dialCode) + " ")]), _vm._v(_vm._s(scope.row.user.contactNumber) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("Email: ")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user3 = scope.row.user) === null || _scope$row$user3 === void 0 ? void 0 : _scope$row$user3.email) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("Age: ")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user4 = scope.row.user) === null || _scope$row$user4 === void 0 ? void 0 : _scope$row$user4.age) + " ")])], 1)], 1)];
      }
    }], null, false, 4003308222)
  }), _c("el-table-column", {
    attrs: {
      width: "400px",
      align: "center",
      label: "Plan Details"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$plan, _scope$row$plan2, _scope$row$plan3, _scope$row$plan4;
        return [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", [_c("b", [_vm._v("Plan: ")])]), _c("el-col", [_vm._v(_vm._s((_scope$row$plan = scope.row.plan) === null || _scope$row$plan === void 0 ? void 0 : _scope$row$plan.title))])], 1)], 1), _c("br"), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("No Of Days: ")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("span", [_vm._v(_vm._s((_scope$row$plan2 = scope.row.plan) === null || _scope$row$plan2 === void 0 ? void 0 : _scope$row$plan2.noOfDays) + " ")]), _vm._v(_vm._s(scope.row.user.contactNumber) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("Price: ")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_vm._v(" " + _vm._s((_scope$row$plan3 = scope.row.plan) === null || _scope$row$plan3 === void 0 ? void 0 : _scope$row$plan3.price) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("No Of Months: ")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_vm._v(" " + _vm._s((_scope$row$plan4 = scope.row.plan) === null || _scope$row$plan4 === void 0 ? void 0 : _scope$row$plan4.noOfMonths) + " ")])], 1)], 1)];
      }
    }], null, false, 4138906924)
  }), _c("el-table-column", {
    attrs: {
      width: "200px",
      align: "center",
      label: "Price",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.price) + " ")];
      }
    }], null, false, 2419043696)
  }), _vm.showCreatedDate ? _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "150",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm._f("moment")(scope.row.createdTimestamp, "MMMM Do YYYY, hh:mm a"),
            placement: "top"
          }
        }, [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "from")))])])];
      }
    }], null, false, 3263888350)
  }) : _vm._e()], 1) : _vm._e(), _vm.listQuery.userId ? _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0 && !this.showSortingOption,
      expression: "total > 0 && !this.showSortingOption"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  }) : _vm._e()], 1) : _c("div", [_c("el-tabs", {
    staticStyle: {},
    attrs: {
      "active-name": _vm.activeTab,
      stretch: true
    },
    on: {
      "tab-click": _vm.handleClick
    }
  }, [_c("el-tab-pane", {
    attrs: {
      name: "first"
    }
  }, [_c("span", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("span", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      "margin-left": "20px"
    }
  }, [_vm._v("Pending")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "dragTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "row-key": "id",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "100",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      width: "130",
      align: "center",
      label: "Enable/Disable",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          key: scope.row.id,
          attrs: {
            url: "/user-transactions/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "Plan Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$plan5;
        return [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Plan Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$plan5 = scope.row.plan) === null || _scope$row$plan5 === void 0 ? void 0 : _scope$row$plan5.title) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Currency Type: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.currencyType) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Price: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.price) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Type: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [scope.row.planTypeIds[0] == 1 || scope.row.planTypeIds[1] == 1 ? _c("span", [_c("el-tag", {
          staticStyle: {
            color: "black",
            margin: "0px 5px",
            cursor: "pointer"
          },
          on: {
            click: function ($event) {
              return _vm.showTrainerData(scope.row.id, true);
            }
          }
        }, [_vm._v("Trainer")])], 1) : _vm._e(), scope.row.planTypeIds[0] == 2 || scope.row.planTypeIds[1] == 2 ? _c("span", [_c("el-tag", {
          staticStyle: {
            color: "black",
            cursor: "pointer"
          },
          on: {
            click: function ($event) {
              return _vm.showTrainerData(scope.row.id, false);
            }
          }
        }, [_vm._v("Nutritions")])], 1) : _vm._e()])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Nutrient Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.totalNutrientSession) + " ")])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Training Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalTrainingSession) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalSession) + " ")])], 1)], 1), _c("br"), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("No Of Days Reschedule: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.noOfDaysReschedule) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("No Of Days Cancelation: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.noOfDaysCancelation) + " ")])], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "User Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$user5, _scope$row$user6, _scope$row$user7;
        return [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" User Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user5 = scope.row.user) === null || _scope$row$user5 === void 0 ? void 0 : _scope$row$user5.fullName) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Email: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user6 = scope.row.user) === null || _scope$row$user6 === void 0 ? void 0 : _scope$row$user6.email) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Contact Number: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user7 = scope.row.user) === null || _scope$row$user7 === void 0 ? void 0 : _scope$row$user7.contactNumber) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.actions"),
      width: "200px",
      "class-name": "fixed-width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-button", {
          staticStyle: {
            background: "green"
          },
          attrs: {
            type: "warning",
            size: "",
            icon: ""
          },
          on: {
            click: function ($event) {
              return _vm.changeStatusToPaid(scope.row);
            }
          }
        }, [_vm._v(" Move To Paid ")])];
      }
    }])
  }), _vm.showCreatedDate ? _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "150",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm._f("moment")(scope.row.createdTimestamp, "MMMM Do YYYY, hh:mm a"),
            placement: "top"
          }
        }, [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "from")))])])];
      }
    }], null, false, 3263888350)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0 && !this.showSortingOption,
      expression: "total > 0 && !this.showSortingOption"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      name: "second"
    }
  }, [_c("span", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("span", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      "margin-left": "20px"
    }
  }, [_vm._v("Paid")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "dragTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "row-key": "id",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "100",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      width: "130",
      align: "center",
      label: "Enable/Disable",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          key: scope.row.id,
          attrs: {
            url: "/user-transactions/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "500",
      align: "left",
      label: "Plan Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$plan6;
        return [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Plan Name:")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s((_scope$row$plan6 = scope.row.plan) === null || _scope$row$plan6 === void 0 ? void 0 : _scope$row$plan6.title) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Currency Type: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.currencyType) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Price: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.price) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Type: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [scope.row.planTypeIds[0] == 1 || scope.row.planTypeIds[1] == 1 ? _c("span", [_c("el-tag", {
          staticStyle: {
            color: "black",
            margin: "0px 5px",
            cursor: "pointer"
          },
          on: {
            click: function ($event) {
              return _vm.showTrainerData(scope.row.id, true);
            }
          }
        }, [_vm._v("Trainer")])], 1) : _vm._e(), scope.row.planTypeIds[0] == 2 || scope.row.planTypeIds[1] == 2 ? _c("span", [_c("el-tag", {
          staticStyle: {
            color: "black",
            cursor: "pointer"
          },
          on: {
            click: function ($event) {
              return _vm.showTrainerData(scope.row.id, false);
            }
          }
        }, [_vm._v("Nutritions")])], 1) : _vm._e()])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Nutrient Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.totalNutrientSession) + " ")])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Training Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalTrainingSession) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalSession) + " ")])], 1)], 1), _c("br"), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("No Of Days Reschedule: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.noOfDaysReschedule) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("No Of Days Cancelation: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.noOfDaysCancelation) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Transaction: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.transactionId) + " ")])], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "User Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$user8, _scope$row$user9, _scope$row$user10;
        return [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" User Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user8 = scope.row.user) === null || _scope$row$user8 === void 0 ? void 0 : _scope$row$user8.fullName) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Email: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user9 = scope.row.user) === null || _scope$row$user9 === void 0 ? void 0 : _scope$row$user9.email) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Contact Number: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user10 = scope.row.user) === null || _scope$row$user10 === void 0 ? void 0 : _scope$row$user10.contactNumber) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.actions"),
      width: "200px",
      "class-name": "fixed-width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: "/sessionMaster/list/" + scope.row.id
          }
        }, [_c("el-button", {
          attrs: {
            type: "warning",
            size: "",
            icon: ""
          }
        }, [_vm._v(" Session ")])], 1), _vm._v("  "), _c("br"), _vm._v(" "), _c("br"), _c("el-button", {
          staticStyle: {
            background: "green"
          },
          attrs: {
            type: "warning",
            size: "",
            icon: ""
          },
          on: {
            click: function ($event) {
              return _vm.changeStatus(scope.row);
            }
          }
        }, [_vm._v(" Active ")])];
      }
    }])
  }), _vm.showCreatedDate ? _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "150",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm._f("moment")(scope.row.createdTimestamp, "MMMM Do YYYY, hh:mm a"),
            placement: "top"
          }
        }, [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "from")))])])];
      }
    }], null, false, 3263888350)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0 && !this.showSortingOption,
      expression: "total > 0 && !this.showSortingOption"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      name: "third"
    }
  }, [_c("span", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("span", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      "margin-left": "20px"
    }
  }, [_vm._v("Active")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "dragTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "row-key": "id",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "100",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      width: "130",
      align: "center",
      label: "Enable/Disable",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          key: scope.row.id,
          attrs: {
            url: "/user-transactions/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "Plan Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$plan7;
        return [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Plan Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$plan7 = scope.row.plan) === null || _scope$row$plan7 === void 0 ? void 0 : _scope$row$plan7.title) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Currency Type: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.currencyType) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Price: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.price) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Type: ")])]), scope.row.planTypeIds ? _c("el-col", {
          attrs: {
            span: 10
          }
        }, [scope.row.planTypeIds[0] == 1 || scope.row.planTypeIds[1] == 1 ? _c("span", [_c("el-tag", {
          staticStyle: {
            color: "black",
            margin: "0px 5px",
            cursor: "pointer"
          },
          on: {
            click: function ($event) {
              return _vm.showTrainerData(scope.row.id, true);
            }
          }
        }, [_vm._v("Trainer")])], 1) : _vm._e(), scope.row.planTypeIds[0] == 2 || scope.row.planTypeIds[1] == 2 ? _c("span", [_c("el-tag", {
          staticStyle: {
            color: "black",
            cursor: "pointer"
          },
          on: {
            click: function ($event) {
              return _vm.showTrainerData(scope.row.id, false);
            }
          }
        }, [_vm._v("Nutritions")])], 1) : _vm._e()]) : _vm._e()], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Nutrient Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.totalNutrientSession) + " ")])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Training Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalTrainingSession) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalSession) + " ")])], 1)], 1), _c("br"), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("No Of Days Reschedule: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.noOfDaysReschedule) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("No Of Days Cancelation: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.noOfDaysCancelation) + " ")])], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "User Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$user11, _scope$row$user12, _scope$row$user13;
        return [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" User Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user11 = scope.row.user) === null || _scope$row$user11 === void 0 ? void 0 : _scope$row$user11.fullName) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Email: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user12 = scope.row.user) === null || _scope$row$user12 === void 0 ? void 0 : _scope$row$user12.email) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Contact Number: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user13 = scope.row.user) === null || _scope$row$user13 === void 0 ? void 0 : _scope$row$user13.contactNumber) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.actions"),
      width: "200px",
      "class-name": "fixed-width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: "/sessionMaster/list/" + scope.row.id
          }
        }, [_c("el-button", {
          attrs: {
            type: "warning",
            size: "",
            icon: ""
          }
        }, [_vm._v(" Session ")])], 1)];
      }
    }])
  }), _vm.showCreatedDate ? _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "150",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm._f("moment")(scope.row.createdTimestamp, "MMMM Do YYYY, hh:mm a"),
            placement: "top"
          }
        }, [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "from")))])])];
      }
    }], null, false, 3263888350)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0 && !this.showSortingOption,
      expression: "total > 0 && !this.showSortingOption"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      name: "forth"
    }
  }, [_c("span", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("span", {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      "margin-left": "20px"
    }
  }, [_vm._v("Completed")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "dragTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "row-key": "id",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "100",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      width: "130",
      align: "center",
      label: "Enable/Disable",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          key: scope.row.id,
          attrs: {
            url: "/user-transactions/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "Plan Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$plan8;
        return [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Plan Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$plan8 = scope.row.plan) === null || _scope$row$plan8 === void 0 ? void 0 : _scope$row$plan8.title) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Currency Type: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.currencyType) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Price: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.price) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Type: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [scope.row.planTypeIds[0] == 1 || scope.row.planTypeIds[1] == 1 ? _c("span", [_c("el-tag", {
          staticStyle: {
            color: "black",
            margin: "0px 5px",
            cursor: "pointer"
          },
          on: {
            click: function ($event) {
              return _vm.showTrainerData(scope.row.id, true);
            }
          }
        }, [_vm._v("Trainer")])], 1) : _vm._e(), scope.row.planTypeIds[0] == 2 || scope.row.planTypeIds[1] == 2 ? _c("span", [_c("el-tag", {
          staticStyle: {
            color: "black",
            cursor: "pointer"
          },
          on: {
            click: function ($event) {
              return _vm.showTrainerData(scope.row.id, false);
            }
          }
        }, [_vm._v("Nutritions")])], 1) : _vm._e()])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Nutrient Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.totalNutrientSession) + " ")])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Training Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalTrainingSession) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("Total Session: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.totalSession) + " ")])], 1)], 1), _c("br"), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("No Of Days Reschedule: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.noOfDaysReschedule) + " ")])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v("No Of Days Cancelation: ")])]), _c("el-col", {
          attrs: {
            span: 10
          }
        }, [_vm._v(" " + _vm._s(scope.row.noOfDaysCancelation) + " ")])], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "User Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$user14, _scope$row$user15, _scope$row$user16;
        return [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" User Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user14 = scope.row.user) === null || _scope$row$user14 === void 0 ? void 0 : _scope$row$user14.fullName) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Email: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user15 = scope.row.user) === null || _scope$row$user15 === void 0 ? void 0 : _scope$row$user15.email) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Contact Number: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user16 = scope.row.user) === null || _scope$row$user16 === void 0 ? void 0 : _scope$row$user16.contactNumber) + " ")])], 1)];
      }
    }])
  }), _vm.showCreatedDate ? _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "150",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm._f("moment")(scope.row.createdTimestamp, "MMMM Do YYYY, hh:mm a"),
            placement: "top"
          }
        }, [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "from")))])])];
      }
    }], null, false, 3263888350)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0 && !this.showSortingOption,
      expression: "total > 0 && !this.showSortingOption"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      title: "",
      visible: _vm.paiddialogVisible,
      width: "30%",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.paiddialogVisible = $event;
      }
    }
  }, [_c("span", [_vm._v("Are you sure want to update status into Paid ??")]), _c("br"), _vm._v(" "), _c("br"), _c("el-form", {
    ref: "paidForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.paidForm,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "",
      prop: "transactionId"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "age",
      placeholder: "Transaction Id"
    },
    model: {
      value: _vm.paidForm.transactionId,
      callback: function ($$v) {
        _vm.$set(_vm.paidForm, "transactionId", $$v);
      },
      expression: "paidForm.transactionId"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.paiddialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateStatus1
    }
  }, [_vm._v("Confirm")])], 1)], 1), _c("el-dialog", {
    attrs: {
      title: "",
      visible: _vm.ongoingdialogVisible,
      width: "30%",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.ongoingdialogVisible = $event;
      }
    }
  }, [_c("span", [_vm._v("Are you sure want to update status into active ??")]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.ongoingdialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateStatus
    }
  }, [_vm._v("Confirm")])], 1)]), _c("el-dialog", {
    attrs: {
      title: "",
      visible: _vm.countdialogVisible,
      width: "50%",
      "before-close": _vm.countHandleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.countdialogVisible = $event;
      }
    }
  }, [_c("el-row", [_vm.countBoolean == true ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("h3", [_vm._v("Trainer Count")]), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Total Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.totalTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Booked Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.bookedTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Completed Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.completedTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Upcoming Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.upcomingTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Cancel Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.cancelTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Cancel By Admin Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.cancelByAdminTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Pending Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.pendingTrainingSessionCount) + " ")])], 1), _c("hr")], 1) : _vm._e()], 1), _c("el-row", [_vm.countBoolean == false ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("h3", [_vm._v("Nutritions Count")]), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Total Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.totalNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Booked Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.bookedNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Completed Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.completedNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Upcoming Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.upcomingNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Cancel Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.cancelNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Cancel By Admin Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.cancelByAdminNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Pending Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.pendingNutritionistSessionCount) + " ")])], 1), _c("hr")], 1) : _vm._e()], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        _vm.countdialogVisible = false;
      }
    }
  }, [_vm._v("Ok")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;