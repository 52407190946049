"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_vm.isUserAdd ? _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select User",
      prop: "userId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.postForm.userId,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "userId", $$v);
      },
      expression: "postForm.userId"
    }
  }, _vm._l(_vm.usersList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.fullName + " / " + item.email,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select Plan",
      prop: "planId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    on: {
      change: _vm.updatePlanData
    },
    model: {
      value: _vm.postForm.planId,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "planId", $$v);
      },
      expression: "postForm.planId"
    }
  }, _vm._l(_vm.planlist, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.title,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Number of Days",
      prop: "noOfDays"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      maxlength: "10",
      name: "age",
      placeholder: "No Of Days"
    },
    model: {
      value: _vm.postForm.noOfDays,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "noOfDays", $$v);
      },
      expression: "postForm.noOfDays"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Currency Type",
      prop: "currencyType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select"
    },
    model: {
      value: _vm.postForm.currencyType,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "currencyType", $$v);
      },
      expression: "postForm.currencyType"
    }
  }, _vm._l(_vm.currencyTypeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Couple plan  ",
      prop: "isCouplePlan"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "Yes",
      "inactive-text": "No"
    },
    model: {
      value: _vm.postForm.isCouplePlan,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "isCouplePlan", $$v);
      },
      expression: "postForm.isCouplePlan"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Package Amount",
      prop: "price"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      maxlength: "10",
      name: "age",
      placeholder: "Amount"
    },
    model: {
      value: _vm.postForm.price,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "price", $$v);
      },
      expression: "postForm.price"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Includes",
      prop: "planTypeIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: true,
      multiple: ""
    },
    on: {
      "remove-tag": _vm.handleRemoveTag
    },
    model: {
      value: _vm.postForm.planTypeIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "planTypeIds", $$v);
      },
      expression: "postForm.planTypeIds"
    }
  }, _vm._l(_vm.includeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _vm.postForm.planTypeIds[0] == 1 || _vm.postForm.planTypeIds[1] == 1 ? _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Total nutrition session",
      prop: "totalNutrientSession"
    }
  }, [_c("el-input", {
    attrs: {
      name: "totalNutrientSession",
      type: "number",
      placeholder: "Total nutrition session"
    },
    on: {
      input: function ($event) {
        return _vm.changeSession();
      }
    },
    model: {
      value: _vm.postForm.totalNutrientSession,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "totalNutrientSession", $$v);
      },
      expression: "postForm.totalNutrientSession"
    }
  })], 1) : _vm._e(), _vm.postForm.planTypeIds[0] == 2 || _vm.postForm.planTypeIds[1] == 2 ? _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Total training session",
      prop: "totalTrainingSession"
    }
  }, [_c("el-input", {
    attrs: {
      name: "totalTrainingSession",
      type: "number",
      placeholder: "Total training session"
    },
    on: {
      input: function ($event) {
        return _vm.changeSession();
      }
    },
    model: {
      value: _vm.postForm.totalTrainingSession,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "totalTrainingSession", $$v);
      },
      expression: "postForm.totalTrainingSession"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Total sessions",
      prop: "totalSession"
    }
  }, [_c("el-input", {
    attrs: {
      readonly: "true",
      name: "noOfDays",
      placeholder: "Total sessions"
    },
    model: {
      value: _vm.postForm.totalSession,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "totalSession", $$v);
      },
      expression: "postForm.totalSession"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "No. of days for reschedule ",
      prop: "noOfDaysReschedule"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      name: "noOfDaysReschedule",
      placeholder: "No. of days for reschedule "
    },
    model: {
      value: _vm.postForm.noOfDaysReschedule,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "noOfDaysReschedule", $$v);
      },
      expression: "postForm.noOfDaysReschedule"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "No. of days for cancellation",
      prop: "noOfDaysCancelation"
    }
  }, [_c("el-input", {
    attrs: {
      name: "noOfDaysCancelation",
      type: "number",
      placeholder: "No. of days for cancellation "
    },
    model: {
      value: _vm.postForm.noOfDaysCancelation,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "noOfDaysCancelation", $$v);
      },
      expression: "postForm.noOfDaysCancelation"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" Add Package ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;