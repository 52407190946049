"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _index = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _firebase = require("@/config/firebase.config");
var _admin = require("@/store/modules/admin");
var _group = require("@/store/modules/group");
var _app = _interopRequireDefault(require("firebase/compat/app"));
require("firebase/compat/firestore/");
var _vuePropertyDecorator = require("vue-property-decorator");
var _services = require("./services");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.serviceLoading = false;
    this.serviceList = [];
    this.listLoading = true;
    this.userName = "";
    this.newList = [];
    this.oldList = [];
    this.list = [];
    this.groupName = null;
    this.userLists = [];
    this.showSortingOption = false;
    this.filterloading = false;
    this.addAccess = true;
    this.loadChat = false;
    this.app = _app.default.initializeApp(_firebase.firebaseConfig);
    this.db = null;
    this.observer = null;
    this.messageRef = null;
    this.unreadMessagesCount = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      serviceId: "",
      sort: "id,DESC",
      filter: {
        name: "$contL",
        enabled: "eq",
        serviceId: "eq"
      }
    };
  }
  async created() {
    setTimeout(() => {
      let block = document.getElementById("block-col");
      console.log(block);
    }, 3000);
    this.listLoading = true;
    this.subscribeToConversations();
    if (this.$route.path == "/orderMenu/" + this.$route.path.split("/").pop() + "/Inbox/" + this.$route.path.split("/").pop()) {
      var id = this.$route.path.split("/").pop();
      this.listQuery.serviceId = id;
      this.addAccess = false;
    }
    this.listLoading = false;
  }
  handleFilter() {
    this.subscribeToConversations();
  }
  async subscribeToConversations() {
    (0, _services.subscribeToUserConversations)(_admin.AdminModule.id, _admin.AdminModule.roleId ? _admin.AdminModule.roleId : 2, this.groupName,
    // pass the group name
    updatedConversations => {
      this.listLoading = true;
      this.list = [];
      this.list = updatedConversations;
      this.listLoading = false;
    });
  }
  async getData() {
    console.log("list", this.list);
  }
  handleGroupClick(groupDetails) {
    _group.GroupModule.saveGroupDetails(groupDetails);
    this.$router.push(groupDetails.tags);
  }
  getRole(roleId) {
    switch (roleId) {
      case 0:
        return "User";
      case 1:
        return "Admin";
      case 2:
        return "Trainer";
      case 3:
        return "Nutritionist";
      default:
        return "Unknown";
    }
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value.label) === index;
  }
  GoToChat(id) {
    this.$router.push("/inbox/chat/" + Number(id));
  }
  remoteMethod(query) {
    if (query !== "") {
      this.filterloading = true;
      setTimeout(() => {
        this.filterloading = false;
        this.userLists = this.list.filter(item => {
          return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }, 200);
    } else {
      this.userLists = [];
    }
  }
  handleBlock(id) {
    this.db.collection("messages").doc(id).update({
      isBlockedByAdmin: true
    });
  }
  handleUnblock(id) {
    this.db.collection("messages").doc(id).update({
      isBlockedByAdmin: false
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "InboxList",
  components: {
    Pagination: _index2.default,
    Enabled: _index.default
  }
})], default_1);
var _default = exports.default = default_1;