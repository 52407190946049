"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _admins = require("@/api/admins");
var _group = require("@/api/group");
var _users = require("@/api/users");
var _index = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _firebase = require("@/config/firebase.config");
var _group2 = require("@/store/modules/group");
var _utils = require("@/utils");
var _compat = _interopRequireDefault(require("firebase/compat"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _services = require("../services");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.app = _compat.default.initializeApp(_firebase.firebaseConfig);
    this.db = null;
    this.postForm = Object.assign({}, _group.defaultGroupData);
    this.selectedAdmins = [];
    this.renderKey = 0;
    this.adminsList = [];
    this.usersList = [];
    this.selectedUsers = [];
    this.members = [];
    this.loading = false;
    this.currentMembers = [];
    this.rules = {
      groupName: [{
        required: true,
        message: "Please enter Group name",
        trigger: ["blur", "change"]
      }],
      groupIcon: [{
        required: true,
        message: "Please Upload Group Icon",
        trigger: ["blur", "change"]
      }]
    };
    this.listQuery = {
      page: 1,
      limit: 1000,
      sort: "id,DESC",
      filter: {}
    };
  }
  created() {
    this.setFirebaseDb();
    // this.getData();
    if (this.isEdit) {
      this.getData();
      this.loadGroupDetails();
    }
  }
  setFirebaseDb() {
    this.db = _compat.default.firestore();
  }
  async getData() {
    try {
      await Promise.all([this.fetchAdmins(), this.fetchUsers()]);
      console.log("All data fetched");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async fetchAdmins() {
    try {
      const data = await (0, _admins.getAdmins)((0, _utils.getQuery)(this.listQuery));
      this.adminsList = data.data;
    } catch (error) {
      const e = error;
      this.$message.error("Error fetching admin list : " + e.message);
    }
  }
  async fetchUsers() {
    try {
      const data = await (0, _users.getUsers)((0, _utils.getQuery)({
        limit: 1000,
        page: 1
      }));
      this.usersList = data.data;
    } catch (error) {
      const e = error;
      this.$message.error("Error fetching users list : " + e.message);
    }
  }
  async remoteMethodAdmin(query) {
    if (query !== "") {
      console.log("query: " + query);
      const queryString = {
        s: {
          $or: [{
            contactNumber: {
              $contL: query
            }
          }, {
            fullName: {
              $contL: query
            }
          }]
        }
      };
      const data = await (0, _admins.getAdmins)((0, _utils.getQuery)(queryString));
      this.adminsList = data;
    } else {
      this.adminsList = [];
    }
  }
  async remoteMethodUser(query) {
    if (query !== "") {
      console.log("query: " + query);
      const queryString = {
        s: {
          $or: [{
            contactNumber: {
              $contL: query
            }
          }, {
            fullName: {
              $contL: query
            }
          }]
        }
      };
      const data = await (0, _users.getUsers)((0, _utils.getQuery)(queryString));
      this.usersList = data;
    } else {
      this.usersList = [];
    }
  }
  async loadGroupDetails() {
    const conversationId = this.$route.params.id;
    if (!conversationId) {
      console.error("No conversationId provided for editing");
      return;
    }
    try {
      // const groupDetails = await fetchConversationById(conversationId);
      const groupDetails = _group2.GroupModule.groupDetails;
      if (groupDetails) {
        var _GroupModule$groupDet;
        this.postForm.groupName = groupDetails.groupName;
        this.postForm.groupIcon = groupDetails.image;
        if (this.postForm.groupIcon) {
          this.renderKey++;
        }
        // const members = await getChatMembers(conversationId);
        const members = (_GroupModule$groupDet = _group2.GroupModule.groupDetails) === null || _GroupModule$groupDet === void 0 ? void 0 : _GroupModule$groupDet.groupMembers;
        this.currentMembers = members;
        const admins = members === null || members === void 0 ? void 0 : members.filter(member => member.role !== 0);
        admins === null || admins === void 0 || admins.forEach(member => {
          this.selectedAdmins.push({
            fullName: member.name,
            id: member.id
          });
        });
        const users = members === null || members === void 0 ? void 0 : members.filter(member => member.role === 0);
        users === null || users === void 0 || users.forEach(member => {
          this.selectedUsers.push({
            fullName: member.name,
            id: member.id
          });
        });
      }
    } catch (error) {
      console.error("Error loading group details: ", error);
    }
  }
  updategroupIcon(res) {
    this.postForm.groupIcon = res;
  }
  removegroupIcon() {
    this.postForm.groupIcon = "";
  }
  mergeUsersAndAdmins() {
    const users = this.selectedUsers.map(user => ({
      id: user.id,
      name: user.fullName,
      profilePic: user.profilePicture,
      role: 0
    }));
    const admins = this.selectedAdmins.map(admin => ({
      id: admin.id,
      name: admin.fullName,
      profilePic: admin.profile,
      role: admin.adminsRoleId
    }));
    this.members = [...users, ...admins];
  }
  async createGroup() {
    try {
      // Merge users and admins before creating the group
      this.mergeUsersAndAdmins();
      console.log("Members:", this.members);
      // Create the group document
      const groupRef = await this.db.collection("conversation").add({
        groupIcon: this.postForm.groupIcon,
        groupName: this.postForm.groupName,
        timestamp: _compat.default.firestore.FieldValue.serverTimestamp()
      });
      console.log("group created", groupRef);
      // Process each member
      for (const member of this.members) {
        const memberRef = this.db.collection("users");
        const memberSnapshot = await memberRef.where("id", "==", member.id).get();
        console.log("Member Found: " + memberSnapshot);
        if (memberSnapshot.empty) {
          // If member is empty, create it
          console.log("Member not Found creating new user");
          try {
            const newMember = await memberRef.add(member);
            console.log("new user created: " + newMember);
          } catch (error) {
            console.error("error adding member: " + error);
          }
        }
        // Add member reference to the group
        try {
          await groupRef.collection("members").add({
            role: member.role,
            timestamp: _compat.default.firestore.FieldValue.serverTimestamp(),
            unreadCount: 0,
            userId: member.id
          });
        } catch (error) {
          const e = error;
          console.error("error adding members to group: " + e.message);
        }
        this.$message.success("Successfully added group");
      }
      console.log("Group created with members");
    } catch (error) {
      console.error("Error creating group: ", error);
    }
  }
  async editGroup() {
    try {
      var _this$currentMembers, _this$currentMembers2;
      this.mergeUsersAndAdmins();
      await (0, _services.editGroupDetails)(this.$route.params.id, {
        groupName: this.postForm.groupName,
        image: this.postForm.groupIcon
      });
      const currentMemberIds = (_this$currentMembers = this.currentMembers) === null || _this$currentMembers === void 0 ? void 0 : _this$currentMembers.map(member => member.id);
      const newMemberIds = this.members.map(member => member.id);
      const membersToAdd = this.members.filter(member => !(currentMemberIds !== null && currentMemberIds !== void 0 && currentMemberIds.includes(member.id)));
      const membersToRemove = (_this$currentMembers2 = this.currentMembers) === null || _this$currentMembers2 === void 0 ? void 0 : _this$currentMembers2.filter(member => !newMemberIds.includes(member.id)).map(member => member.id);
      await (0, _services.addMembersToGroup)(this.$route.params.id, membersToAdd);
      await (0, _services.removeMembersFromGroup)(this.$route.params.id, membersToRemove);
      this.$message.success("Successfully updated group");
      console.log("Group updated with members");
    } catch (error) {
      console.error("Error updating group: ", error);
    }
  }
  async submitForm() {
    this.$refs.postForm.validate(async valid => {
      if (valid) {
        if (this.isEdit) {
          await this.editGroup();
        } else {
          await this.createGroup();
        }
        this.$router.push("/inbox/list");
      }
    });
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "GroupAddForm",
  components: {
    UploadImage: _index.default
  }
})], default_1);
var _default = exports.default = default_1;