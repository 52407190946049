"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifyOtp = exports.updateTrainer = exports.sendOtp = exports.register = exports.logout = exports.login = exports.logPostTrainer = exports.getTrainers = exports.getTrainerStats = exports.getTrainerInfo = exports.getTrainerById = exports.getTrainerByEmail = exports.getSlots = exports.getLogsTrainer = exports.forgotPassword = exports.deleteTrainer = exports.defaultTrainerData = exports.changePasswordLink = exports.changePassword = exports.addSlot = exports.UpdatePassword = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultTrainerData = exports.defaultTrainerData = {
  id: 0,
  enabled: true,
  fullName: '',
  email: '',
  contactNumber: '',
  password: '',
  gender: '',
  profile: '',
  lastLogin: '',
  adminsRoleId: null,
  createdByFullName: '',
  employeeCode: null,
  pxlCode: null,
  pxlCodeId: null,
  workout: [{}],
  workoutIds: [],
  dialCode: '',
  countryCode: '',
  about: '',
  adaharNo: '',
  aadharFromInage: '',
  aadharBackInage: '',
  document: null,
  documentId: null,
  documentFromImage: '',
  documentBackImage: '',
  address: '',
  avatar: '',
  status: 0,
  priority: 0,
  accessToken: '',
  adminsRole: '',
  instaUrl: ''
};
const getTrainers = params => (0, _request.default)({
  url: '/admins',
  method: 'get',
  params
});
exports.getTrainers = getTrainers;
const getTrainerStats = () => (0, _request.default)({
  url: '/admins/statistic',
  method: 'get'
});
exports.getTrainerStats = getTrainerStats;
const getTrainerById = id => (0, _request.default)({
  url: `/admins/${id}`,
  method: 'get'
});
// export const getAdminInfo = (data: any) =>
//   request({
//     url: '/admins/me',
//     method: 'post',
//     data
//   })
exports.getTrainerById = getTrainerById;
const getTrainerByEmail = adminemail => (0, _request.default)({
  url: `/admins/${adminemail}`,
  method: 'get'
});
exports.getTrainerByEmail = getTrainerByEmail;
const updateTrainer = (id, data) => (0, _request.default)({
  url: `/admins/${id}`,
  method: 'patch',
  data
});
exports.updateTrainer = updateTrainer;
const deleteTrainer = adminname => (0, _request.default)({
  url: `/admins/${adminname}`,
  method: 'delete'
});
exports.deleteTrainer = deleteTrainer;
const login = data => (0, _request.default)({
  url: '/admins/login',
  method: 'post',
  data
});
exports.login = login;
const forgotPassword = data => (0, _request.default)({
  url: '/admins/forgot',
  method: 'post',
  data
});
exports.forgotPassword = forgotPassword;
const sendOtp = data => (0, _request.default)({
  url: '/admins/send-otp',
  method: 'post',
  data
});
exports.sendOtp = sendOtp;
const verifyOtp = data => (0, _request.default)({
  url: '/admins/verify-otp',
  method: 'post',
  data
});
exports.verifyOtp = verifyOtp;
const getTrainerInfo = data => (0, _request.default)({
  url: '/admins/me',
  method: 'post',
  data
});
exports.getTrainerInfo = getTrainerInfo;
const changePassword = data => (0, _request.default)({
  url: '/admins/reset-password',
  method: 'post',
  data
});
exports.changePassword = changePassword;
const UpdatePassword = data => (0, _request.default)({
  url: '/admins/change-password',
  method: 'put',
  data
});
exports.UpdatePassword = UpdatePassword;
const changePasswordLink = data => (0, _request.default)({
  url: '/admins/check-link',
  method: 'post',
  data
});
exports.changePasswordLink = changePasswordLink;
const logout = () => (0, _request.default)({
  url: '/admins/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/admins/',
  method: 'post',
  data
});
exports.register = register;
const logPostTrainer = data => (0, _request.default)({
  url: '/admin-logs',
  method: 'post',
  data
});
exports.logPostTrainer = logPostTrainer;
const getLogsTrainer = params => (0, _request.default)({
  url: '/admin-logs',
  method: 'get',
  params
});
exports.getLogsTrainer = getLogsTrainer;
const getSlots = params => (0, _request.default)({
  url: '/slot/trainer-slots',
  method: 'get',
  params
});
exports.getSlots = getSlots;
const addSlot = data => (0, _request.default)({
  url: '/slot/add-slot',
  method: 'post',
  data
});
exports.addSlot = addSlot;