"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _vuePropertyDecorator = require("vue-property-decorator");
var _sessionMaster = require("@/api/sessionMaster");
var _session = require("@/api/session");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _moment = _interopRequireDefault(require("moment"));
// import { getService } from '@/api/service'

let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = Object.assign({}, _sessionMaster.defaultSessionMasterData);
    this.loading = false;
    this.slotsSearchLoading = false;
    this.slotBookingLoading = false;
    this.additionalLoading = false;
    this.uploadingstatus = false;
    this.percentage = 0;
    this.tags = [];
    this.slotList = [];
    this.customColor = '#409eff';
    this.customColors = [{
      color: '#f56c6c',
      percentage: 20
    }, {
      color: '#e6a23c',
      percentage: 40
    }, {
      color: '#5cb87a',
      percentage: 60
    }, {
      color: '#1989fa',
      percentage: 80
    }, {
      color: '#6f7ad3',
      percentage: 100
    }];
    this.serviceList = [];
    this.selectDays = [{
      label: 'Sunday',
      value: 0
    }, {
      label: 'Monday',
      value: 1
    }, {
      label: 'Tuesday',
      value: 2
    }, {
      label: 'Wednesday',
      value: 3
    }, {
      label: 'Thursday',
      value: 4
    }, {
      label: 'Friday',
      value: 5
    }, {
      label: 'Saturday',
      value: 6
    }];
    this.typeList = [{
      label: 'Trainer',
      value: 0
    }, {
      label: 'Nutrionist',
      value: 1
    }];
    this.rules = {
      noOfUSession: [{
        required: true,
        message: 'Please enter session',
        trigger: 'blur'
      }],
      startDate: [{
        required: true,
        message: 'Please select start date',
        trigger: 'blur'
      }],
      day: [{
        required: true,
        message: 'Please select day',
        trigger: 'blur'
      }],
      type: [{
        required: true,
        message: 'Trainer or nutrionist',
        trigger: 'blur'
      }]
    };
    this.sessionList = [];
    this.sessionQuery = {
      userTransactionId: Number(this.$route.params.id)
    };
  }
  aa(abc) {
    this.percentage = abc;
  }
  pdfUrlClose(tags) {
    // this.postForm.url=''
    // this.postForm.tags=[]
  }
  async getSlots() {
    // Validate the form
    this.$refs.postForm.validate(async valid => {
      if (valid) {
        this.slotsSearchLoading = true;
        try {
          const startData = this.postForm.startDate;
          const params = {
            startDate: (0, _moment.default)(startData).format('YYYY-MM-DD'),
            day: this.postForm.day.toLocaleLowerCase(),
            noOfSessions: this.postForm.noOfUSession,
            type: this.postForm.type
          };
          const slotData = await (0, _sessionMaster.getSessionSlots)(params);
          if (slotData) {
            this.slotList = slotData.data;
          }
        } catch (error) {
          // Handle the error
          console.error('Error fetching session slots:', error);
          // Optionally, set an error message to display to the user
          this.$message.error('An error occurred while fetching session slots. Please try again later.');
        } finally {
          // Ensure loading state is reset
          this.slotsSearchLoading = false;
        }
      } else {
        return false;
      }
    });
  }
  async handleBookSlot(data) {
    const trainerName = data.trainerData.name;
    if (data) {
      this.$confirm(this.$tc(`Are you sure want to book slot of ${trainerName} for ${this.postForm.noOfUSession} sessions starting from ${(0, _moment.default)(String(this.postForm.startDate)).format('YYYY-MM-DD')} on every ${this.postForm.day} ?`, undefined, trainerName), this.$tc('Book Slot'), {
        confirmButtonText: this.$tc('Book Slot'),
        confirmButtonClass: 'el-button--success',
        cancelButtonText: 'Cancel',
        type: 'success'
      }).then(async () => {
        // deleteUser(data).then(deleted => {
        //   this.getList()
        // })
        this.slotBookingLoading = true;
        const payload = {
          startDate: (0, _moment.default)(String(this.postForm.startDate)).format('YYYY-MM-DD'),
          day: this.postForm.day.toLocaleLowerCase(),
          noOfSessions: this.postForm.noOfUSession,
          slotId: data.slotData.id,
          adminId: data.trainerData.id,
          userTransactionId: this.$route.params.id
        };
        const res = await (0, _sessionMaster.BookSession)(payload);
        if (res.responseCode == 200) {
          this.$message({
            type: 'success',
            duration: 8000,
            message: 'Congratulations!!,You Booked Slots of ' + trainerName + ' for ' + this.postForm.noOfUSession + ' sessions'
          });
          this.getSlots();
          this.getSession();
        }
        if (res.responseCode == 300) {
          this.$message({
            type: 'warning',
            duration: 8000,
            message: 'OOps!!,This slots is already booked.'
          });
        }
        if (res.responseCode == 301) {
          this.$message({
            type: 'error',
            duration: 8000,
            message: 'OOps!!,This slots is not available.'
          });
        }
        this.slotBookingLoading = false;
        // 200 booked
        // 300 already booked
        console.log(res, 'slot Booked data');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$tc('Booking Cancelled')
        });
      });
    }
  }
  created() {
    this.getSession();
    // this.fetchServices()
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _sessionMaster.defaultSessionMasterData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _sessionMaster.getSessionMasterById)(id);
      this.postForm = data;
      // this.setPageTitle(title)
    } catch (err) {
      Promise.reject(err);
    }
  }
  async getSession() {
    try {
      const sessionData = await (0, _session.getSessionsData)((0, _utils.getQuery)(this.sessionQuery));
      this.sessionList = sessionData;
      console.log(this.sessionList, 'session');
      // this.total = data.total;
    } catch (error) {
      // this.listLoading = false;
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  beforeUpload(file) {
    const doc = file.type == 'application/json';
    if (!doc) {
      this.$message.error('Only json file upload');
    }
    return doc;
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  handleDelete(data) {
    console.log(data, 'data');
    if (data) {
      const statusData = {
        status: 3
      };
      this.$confirm(this.$tc('table.deleteConfirmation', undefined, {
        name: data.name
      }), this.$tc('table.delete'), {
        confirmButtonText: this.$tc('table.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => {
        (0, _session.updatesessions)(data.id, statusData).then(deleted => {
          this.getSlots();
          this.getSession();
        });
      })
      // .then(() => {
      //   deletesessions(data.id).then(deleted => {
      //     this.getSlots()
      //   })
      // })
      .catch(() => {
        this.$message({
          type: 'info',
          message: this.$tc('table.deleteCanceled')
        });
      });
    }
  }
  changeSession(val) {
    if (Number(val) > this.sessionList.pendingTrainingSessionCount) {
      this.$message({
        type: 'error',
        duration: 8000,
        message: 'OOps!!, No Of Session exceed.'
      });
      this.postForm.noOfUSession = null;
    } else {
      this.postForm.noOfUSession = this.postForm.noOfUSession;
    }
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
  }
  async uploadAdditionalFile(param) {
    var fileObj = param.file;
    var form = new FormData();
    form.append('file', fileObj);
    var config = {
      onUploadProgress: progressEvent => {
        this.percentage = Number(Math.round(progressEvent.loaded * 100 / progressEvent.total));
        this.uploadingstatus = true;
        // console.log(percentage1)
        // this.aa(percentage1);
        // this.percentage
      }
    };
    await (0, _common.uploadFile)(form, event => {
      this.additionalLoading = true;
      this.uploadingstatus = true;
      this.percentage = Number(Math.round(event.loaded * 100 / event.total));
      console.log(event);
    }).then(res => {
      // this.postForm.url = res.toString()
      this.additionalLoading = false;
      this.uploadingstatus = false;
    });
    this.additionalLoading = false;
  }
  // private async fetchServices() {
  //   const data: any = await getService(getQuery({
  //     enabled: true,
  //     filter: {
  //       enabled: 'eq'
  //     }
  //   }))
  //   this.serviceList = data
  // }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _sessionMaster.updateSessionMaster)(this.postForm.id, this.postForm);
        this.$notify({
          title: 'Success',
          message: 'Session Master added successfully',
          type: 'success',
          duration: 2000
        });
        _router.default.push('/sessionMaster/list/' + this.postForm.userTransactionId);
      } else {
        this.postForm.userTransactionId = Number(this.$route.params.id);
        await (0, _sessionMaster.addSessionMaster)(this.postForm);
        this.$notify({
          title: 'Success',
          message: 'Session Master added successfully',
          type: 'success',
          duration: 2000
        });
        _router.default.push('/sessionMaster/list/' + this.postForm.userTransactionId);
      }
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'SessionMasterDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;