"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$groupInfo;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.chatLoading,
      expression: "chatLoading"
    }],
    staticClass: "wrapper app-container"
  }, [_c("header", [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      "align-items": "center"
    }
  }, [_c("router-link", {
    attrs: {
      to: "/inbox/list"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-right": "40px"
    },
    attrs: {
      type: "primary",
      icon: "el-icon-d-arrow-left"
    }
  }, [_vm._v("Back")])], 1), _c("router-link", {
    attrs: {
      to: `/inbox/group-edit/${_vm.groupInfo.id}`
    }
  }, [_c("img", {
    staticClass: "messenger-dp",
    staticStyle: {
      width: "40px",
      height: "40px",
      "border-radius": "50%",
      "margin-right": "10px"
    },
    attrs: {
      src: (_vm$groupInfo = _vm.groupInfo) === null || _vm$groupInfo === void 0 ? void 0 : _vm$groupInfo.image
    }
  })]), _c("router-link", {
    attrs: {
      to: `/inbox/group-edit/${_vm.groupInfo.id}`
    }
  }, [_c("h2", {
    staticStyle: {
      "font-size": "16px",
      margin: "0"
    }
  }, [_vm._v(_vm._s(_vm.groupInfo.groupName))])])], 1), _c("div")]), _c("el-dialog", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.uploadingimage,
      expression: "uploadingimage"
    }],
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      title: "Send Images",
      visible: _vm.uploadImageDialog,
      width: "50%",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.uploadImageDialog = $event;
      }
    }
  }, [_c("br"), _c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    staticStyle: {
      "margin-top": "-29px"
    },
    attrs: {
      name: "image",
      "on-change": _vm.uploadImages,
      "list-type": "picture-card",
      action: "https://jsonplaceholder.typicode.com/posts/",
      accept: "image/jpeg,image/png",
      "before-upload": _vm.beforeUpload,
      "auto-upload": true,
      "file-list": _vm.fileList,
      drag: "",
      limit: 1
    },
    on: {
      "handle-upload": _vm.updateImage
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function ({
        file
      }) {
        return _c("div", {}, [_c("img", {
          staticClass: "el-upload-list__item-thumbnail",
          attrs: {
            src: file.url,
            alt: ""
          }
        }), _c("span", {
          staticClass: "el-upload-list__item-actions"
        }, [_c("span", {
          staticClass: "el-upload-list__item-preview",
          on: {
            click: function ($event) {
              return _vm.handlePictureCardPreview(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-zoom-in"
        })]), !_vm.disabled ? _c("span", {
          staticClass: "el-upload-list__item-delete",
          on: {
            click: function ($event) {
              return _vm.handleRemove(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-delete"
        })]) : _vm._e()])]);
      }
    }]),
    model: {
      value: _vm.imagelist,
      callback: function ($$v) {
        _vm.imagelist = $$v;
      },
      expression: "imagelist"
    }
  }, [_c("i", {
    staticClass: "el-icon-plus",
    attrs: {
      slot: "default"
    },
    slot: "default"
  }), _c("span", {
    staticStyle: {
      color: "red",
      "margin-left": "10px"
    }
  }, [_vm._v("only .JPG/PNG files")])]), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })]), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 1000 * 1000 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px",
      "margin-top": "35px"
    }
  })]), _c("span", {
    staticClass: "dialog-footer",
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticStyle: {
      "text-align": "center"
    },
    on: {
      click: function ($event) {
        _vm.uploadImageDialog = false;
        _vm.handleCancel();
      }
    }
  }, [_vm._v("Cancel")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: _vm.confirmuploadbtn
    },
    on: {
      click: _vm.finalUpload
    }
  }, [_vm._v("Confirm")])], 1)], 1), _c("section", {
    staticClass: "text-msg-parent"
  }, [_c("section", {
    staticClass: "text-msg-box",
    attrs: {
      id: "text-msg-box"
    }
  }, _vm._l(_vm.messages, function (msg, index) {
    return _c("div", {
      key: "index-" + index,
      class: ["message", _vm.sentOrReceived(msg)],
      attrs: {
        id: "last-message" + index
      }
    }, [_c("img", {
      attrs: {
        src: _vm.sentOrReceivedUserImage(msg)
      }
    }), msg.type == 0 ? _c("p", {
      staticClass: "msg-text"
    }, [_c("span", {
      staticClass: "username",
      style: `color:${msg.senderId == _vm.loggedInUserId ? "black" : "black"}`
    }, [_vm._v(" " + _vm._s(_vm.sentOrReceivedUserName(msg)) + " ")]), _vm._v(" " + _vm._s(msg.text) + " "), _c("span", {
      staticClass: "timestamp"
    }, [_vm._v(_vm._s(msg.timestamp))])]) : _vm._e(), msg.type == 3 ? _c("div", {
      staticClass: "msg-audio"
    }, [msg.type == 3 ? _c("audio", {
      attrs: {
        controls: ""
      }
    }, [_c("source", {
      attrs: {
        src: msg.message,
        type: "audio/m4a"
      }
    }), _c("source", {
      attrs: {
        src: msg.message,
        type: "audio/ogg"
      }
    }), _c("source", {
      attrs: {
        src: msg.message,
        type: "audio/mpeg"
      }
    }), _c("source", {
      attrs: {
        src: msg.message,
        type: "audio/mp3"
      }
    })]) : _vm._e(), _c("div", {
      staticClass: "timestamp"
    }, [_vm._v(_vm._s(msg.timestamp))])]) : _vm._e(), msg.type == 1 ? _c("div", {
      staticClass: "demo-image__preview"
    }, [_c("span", {
      staticClass: "username",
      style: `color:${msg.senderId == _vm.loggedInUserId ? "black" : "black"}`
    }, [_vm._v(" " + _vm._s(_vm.sentOrReceivedUserName(msg)) + " ")]), _c("div", {
      staticStyle: {
        padding: "2px 0 2px 0"
      }
    }, [msg.type == 1 ? _c("el-image", {
      staticClass: "msg-img",
      staticStyle: {
        margin: "10px 0 10px 0"
      },
      attrs: {
        src: msg.image,
        "preview-src-list": _vm.srcList
      }
    }) : _vm._e()], 1), _c("div", {
      staticClass: "timestamp"
    }, [_vm._v(_vm._s(msg.timestamp))])]) : _vm._e()]);
  }), 0), _c("div", {
    ref: "scrollable"
  })]), _c("section", {
    staticClass: "input-container"
  }, [_c("section", {
    staticClass: "audio-preview-container",
    attrs: {
      id: "audio-preview-container"
    }
  }, [_c("div", {
    staticClass: "audio-preview-controller"
  }, [_c("audio", {
    attrs: {
      controls: "",
      id: "audio-preview"
    }
  }, [_c("source", {
    attrs: {
      src: _vm.audioPreview,
      type: "audio/m4a"
    }
  }), _c("source", {
    attrs: {
      src: _vm.audioPreview,
      type: "audio/ogg"
    }
  }), _c("source", {
    attrs: {
      src: _vm.audioPreview,
      type: "audio/mpeg"
    }
  }), _c("source", {
    attrs: {
      src: _vm.audioPreview,
      type: "audio/mp3"
    }
  }), _c("source", {
    attrs: {
      src: _vm.audioPreview
    }
  })])]), _c("div", {
    staticClass: "audio-actions"
  }, [_c("div", {
    staticClass: "cancel-send-audio",
    on: {
      click: _vm.handleCancelAudio
    }
  }, [_vm._v("Cancel")]), _c("div", {
    staticClass: "send-audio",
    on: {
      click: _vm.handleSendAudio
    }
  }, [_vm._v("Send Audio")])])]), _c("form", {
    staticClass: "send-msg-container",
    on: {
      submit: function ($event) {
        $event.preventDefault();
        return _vm.sendMessage.apply(null, arguments);
      }
    }
  }, [_c("el-input", {
    staticClass: "msg-input",
    attrs: {
      type: "text",
      placeholder: "Enter your message!"
    },
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }), _c("el-button", {
    staticClass: "msg-cont-btn",
    attrs: {
      type: "primary",
      icon: "el-icon-picture-outline-round",
      circle: ""
    },
    on: {
      click: function ($event) {
        _vm.uploadImageDialog = true;
      }
    }
  }), _c("el-button", {
    staticClass: "msg-cont-btn",
    attrs: {
      type: "primary",
      icon: "el-icon-microphone",
      circle: ""
    },
    on: {
      click: _vm.recordAudio
    }
  }), _c("el-button", {
    staticClass: "msg-input-send",
    attrs: {
      type: "success",
      disabled: !_vm.message
    },
    on: {
      click: _vm.sendMessage
    }
  }, [_vm._v(" Send ")])], 1), _c("div", {
    staticClass: "record-container",
    attrs: {
      id: "record-container"
    }
  }, [_c("span", {
    staticStyle: {
      margin: "auto",
      "font-size": "10px",
      color: "green"
    }
  }, [_vm._v("Recording")]), _c("el-button", {
    staticStyle: {
      margin: "auto"
    },
    attrs: {
      type: "warning",
      icon: "el-icon-video-pause",
      id: "btnStop",
      circle: ""
    }
  })], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;